<template>
  <div class="footer-sticky">
    <div class="footer-invert-radius">
      <div class="footer-invert-radius-inner"></div>
    </div>
    <div class="footer">
      <div class="footer-grid">
        <div class="footer-section">
          <!-- <div class="footer-col">
            <span>Stay in touch</span>
            <a href="#" class="footer-link">Newsletter</a>
            <NuxtLink to="/blog" class="footer-link"> Blog </NuxtLink>
          </div> -->
          <div class="footer-col">
            <span>On social media</span>
            <a
              href="https://www.linkedin.com/company/ansearch/"
              class="footer-link"
            >
              <Icon name="logos:linkedin-icon" /> Linkedin
            </a>
            <a href="https://twitter.com/GetAnsearch" class="footer-link">
              <Icon name="logos:twitter" />
              Twitter
            </a>
            <a
              href="https://www.youtube.com/channel/UCH6x0yF9N5BlYwP9opTZa3A"
              class="footer-link"
            >
              <Icon name="logos:youtube-icon" />
              Youtube
            </a>
          </div>
          <div class="footer-col">
            <span>Company</span>
            <NuxtLink to="/about" class="footer-link"> About </NuxtLink>
            <NuxtLink to="/blog" class="footer-link"> Blog </NuxtLink>
            <!-- <NuxtLink to="/jobs" class="footer-link"> Career </NuxtLink> -->
          </div>
          <div class="footer-col">
            <span>Legal</span>
            <NuxtLink to="/policies/privacy" class="footer-link"> Privacy </NuxtLink>
            <NuxtLink to="/policies/terms" class="footer-link"> Terms </NuxtLink>
          </div>
          <div class="footer-col">
            <span>Help</span>
            <NuxtLink to="/contact" class="footer-link"> Contact </NuxtLink>
            <a href="https://status.ansearch.net/" class="footer-link"> Status </a>
          </div>
        </div>
        <div class="footer-section">
          <div class="footer-col">
            <span>Ansearch</span>
            <p class="footer-shaded">
              Copyright &copy; {{ todayYear }}, Ansearch Ltd.
            </p>
            <p class="footer-shaded">
              Ansearch Ltd is a company registered in Scotland number SC756179
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Required to put this here, or it will create issue with SSR
const todayYear = new Date().getFullYear();
</script>

<style scoped>
.footer-sticky {
  margin-top: auto;
}

.footer {
  padding: 2rem;
  background-color: var(--background-inverted);
  color: var(--text-inverted);
  border-radius: 0px 60px 0 0;
}

.footer-invert-radius {
  background-color: var(--background-inverted);
}

.footer-invert-radius-inner {
  border-radius: 0 0 0 60px;
  background-color: var(--background);
  min-height: 60px;
}

.footer-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-around;
}

.footer-section {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.footer-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 30ch;
}

.footer-col > span {
  font-weight: 600;
}

.footer-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--cancel-colour);
}

.footer-shaded {
  color: var(--cancel-colour);
  margin: 0;
}

.footer-link:hover {
  color: var(--text-inverted);
}
</style>
